// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBeBWXaMG_MiRUkbCASIsr9xp_xatJchdM",
    authDomain: "tesa-radio.firebaseapp.com",
    projectId: "tesa-radio",
    storageBucket: "tesa-radio.appspot.com",
    messagingSenderId: "558985101546",
    appId: "1:558985101546:web:21b66917674f8228815793",
    measurementId: "G-J2TDMF6D1Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
