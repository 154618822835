import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [IntroGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'podcast',
    loadChildren: () => import('./pages/podcast/podcast.module').then( m => m.PodcastPageModule)
  },
  {
    path: 'shows',
    loadChildren: () => import('./pages/shows/shows.module').then( m => m.ShowsPageModule)
  },
  // {
  //   path: 'donate',
  //   loadChildren: () => import('./pages/donate/donate.module').then( m => m.DonatePageModule)
  // },
  {
    path: 'shout-out',
    loadChildren: () => import('./pages/shout-out/shout-out.module').then( m => m.ShoutOutPageModule)
  },
  {
    path: 'demand',
    loadChildren: () => import('./pages/demand/demand.module').then( m => m.DemandPageModule)
  },
  {
    path: 'radio',
    loadChildren: () => import('./pages/radio/radio.module').then( m => m.RadioPageModule)
  },
  {
    path: 'inframe-radio',
    loadChildren: () => import('./pages/inframe-radio/inframe-radio.module').then( m => m.InframeRadioPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
